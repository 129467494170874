<template>
  <b-card
    no-body
    class="box-shadow-card"
  >
    <b-card-header class="pb-1">
      <b-media
        no-body
        style="align-items: center"
      >
        <b-media-aside class="mr-1">
          <b-img
            width="28"
            :src="getAddressType(address)"
          />
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            {{ address.title }}
          </h5>
        </b-media-body>
      </b-media>
      <div class="wrap-actions">
        <b-img
          width="28"
          :src="require('@/assets/images/app-icons/address/Edit.png')"
          class="mr-1"
          @click="$emit('edit-address', address)"
        />
        <b-img
          width="28"
          :src="require('@/assets/images/app-icons/address/Delete.png')"
          @click="$emit('delete-address', address)"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-media
        no-body
        style="align-items: center"
      >
        <b-media-aside class="mr-1">
          <b-img
            width="28"
            :src="require('@/assets/images/app-icons/address/Location.png')"
          />
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            {{ address.map_address }}
          </h5>
        </b-media-body>
      </b-media>
      <b-card-text class="mb-0">
        <p class="m-0 mt-2">
          {{ $t('Flat No') }}: {{ address.flat_no }}
        </p>
        <p class="m-0">
          {{ $t('Building/Villa Name') }}: {{ address.building }}
        </p>
        <p class="m-0">
          {{ $t('Street') }}: {{ address.street }}
        </p>
        <div class="d-flex justify-content-between wrapper-responsive-address">
          <p class="mb-0">
            {{ $t('Zip/Pincode') }}: {{ address.zipcode }}
          </p>
          <div
            v-if="showUseAddress"
            class="d-flex justify-content-end mt-0"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="text-white"
              @click="$emit('deliver-here', address)"
            >
              <feather-icon
                v-if="address.id == selectedAddress"
                icon="CheckSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              {{ $t('Use this address') }}
            </b-button>
          </div>
        </div>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BMedia, BMediaAside, BMediaBody, BImg, BCardText, BButton, BCardBody } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BImg,
    BCardHeader,
    BMedia,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    showUseAddress: {
      type: Boolean,
      default: false,
    },
    selectedAddress: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    getAddressType(address) {
      // eslint-disable-next-line global-require
      if (address.address_type === 'Home') return require('@/assets/images/app-icons/address/Home.png')
      // eslint-disable-next-line global-require
      if (address.address_type === 'Work') return require('@/assets/images/app-icons/address/Work.png')
      // eslint-disable-next-line global-require
      if (address.address_type === 'Other') return require('@/assets/images/app-icons/address/Others.png')
      return ''
    },
  },
}
</script>
