<template>
  <div class="user-nav-items">
    <span class="feather-icon-wrapper" @click="openNotifications">
      <b-link>
        <span class="badge badge-up badge-pill badge-primary">{{ notificationCount }}</span>
        <img :src="require('@/assets/images/app-icons/notification.png')" width="21">
      </b-link>
    </span>
    <NavChatBar />
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'
import NavChatBar from '@/components/common/NavChatBar.vue';
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    NavChatBar,
  },
  computed: {
    ...mapGetters('userBasic', [
      'notificationCount'
    ]),
  },
  mounted() {
    this.getNotificationCount()
  },
  methods: {
    ...mapActions('userBasic', ['getNotificationCount']),
    openNotifications() {
      eventBus.$emit('show-users-notifications')
    },
  },
}
</script>

<style scoped>
.user-nav-items .feather-icon-wrapper {
  margin: 0px 10px;
  position: relative;
}

.user-nav-items img.chat-icon-dropdown {
  position: absolute;
  width: 25px;
  right: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .user-nav-items .feather-icon-wrapper li {
    float: unset;
  }
}
</style>
