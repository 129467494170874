<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none hamburger-menu">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <!-- <feather-icon
            icon="MenuIcon"
            size="21"
          /> -->
          <img style="max-width: 40px" :src="require('@/assets/images/logo/mom-logo-icon-72.png')" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto nav-wrapper-area">
      <li v-if="$route.meta.showLocation && isUser && $store.getters['app/windowSize'] > 767"
        class="search-location-size">
        <location-bar />
      </li>
      <li v-if="isLoggedIn && (isAgent || isProvider || isStore)" class="page-name-title">
        <p class="mb-0">
          {{ $t($route.meta.pageTitle) }}
        </p>
      </li>
      <div v-if="isLoggedIn && isAgent && $route.path === '/provider/agent/dashboard'"
        class="mr-1 cursor-pointer pointer maximize-icon-wrapper">
        <img :src="require('@/assets/images/app-images/agent-db-header-fullscreen.png')" height="26"
          @click="maximizeScreen" />
        <!-- <feather-icon
          icon="MaximizeIcon"
          size="21"
          @click="maximizeScreen"
        /> -->
      </div>
      <div v-if="isLoggedIn && (isAgent || isProvider || isStore)" class="d-flex align-items-center">
        <b-form-checkbox v-model="isAgentOnline" switch inline @change="toggleOnlineOffline" />
        <!-- <label class="form-extra-label"> {{ isAgentOnline ? $t('Online') : $t('Offline') }}</label>
        </b-form-checkbox> -->
      </div>
      <provider-nav-items v-if="isLoggedIn && isProvider && !isAgent && isProviderUrl" />
      <agent-nav-items v-if="isLoggedIn && isProvider && isAgent" />
      <shop-nav-items v-if="isLoggedIn && isStore && isShopUrl" />
      <on-going-service v-if="isUser && isLoggedIn" />

      <user-addresses v-if="isUser" ref="userAddressRef" class="user-selection-address" :show-default="false"
        :show-close="true" @set-new-address="setNewAddress" />
      <locale v-if="!isMobileView" />
      <cart-dropdown v-if="isUser && isLoggedIn" />
      <user-nav-items v-if="isLoggedIn && isUser" />
      <referral ref="referralComponent" />
      <user-notifications ref="userNotificationComponent" />
      <b-dropdown-item v-if="!isLoggedIn" :to="{ path: '/user/login' }" class="dropdown-cart mr-25"
        menu-class="dropdown-menu-media" right>
        <span class="font-weight-bold mr-25 text-uppercase">{{ $t('Login') }}</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="!isLoggedIn" :to="{ path: '/user/registration' }" class="dropdown-cart mr-25"
        menu-class="dropdown-menu-media" right>
        <span class="font-weight-bold mr-25 text-uppercase">{{ $t('Sign Up') }}</span>
      </b-dropdown-item>
      <b-nav-item-dropdown v-if="isLoggedIn" right toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user" @hide="hideNavBar($event)">
        <template #button-content>
          <div v-if="!isAdmin" class="d-sm-flex d-none user-nav">
            <p v-if="!isStore" class="user-name font-weight-bolder mb-0">
              {{ getUserInfo.first_name }} {{ getUserInfo.last_name }}
            </p>
            <p v-else class="user-name font-weight-bolder mb-0">
              {{ getUserInfo.store_name }}
            </p>
            <span v-if="!isStore && !isUser" class="user-status">{{ getUserInfo.user_type }}</span>
          </div>
          <div v-if="isAdmin" class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getUserInfo.name }}
            </p>
            <span class="user-status">{{ getUserInfo.type }}</span>
          </div>
          <b-avatar variant="light-primary" badge :src="$helpers.imageHelper(getUserInfo.picture)"
            class="badge-minimal navbar-header-logo-image" style="max-height: 100px" badge-variant="success" />
        </template>

        <b-dropdown-item v-if="isLoggedIn && isVisible" link-class="d-flex align-items-center"
          :to="{ name: isUser ? 'user-profile' : isProvider ? 'provider-profile' : isStore ? 'shop-profile' : '' }">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/user/account.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="isLoggedIn && isVisible && !isAgent && isUserOrProvider"
          link-class="d-flex align-items-center"
          :to="{ name: isUser ? 'user-order' : isProvider ? 'provider-order' : isStore ? 'shop-dispatcher' : '' }">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/user/History.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('History') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="false && isLoggedIn && isVisible && !isAgent && isUserOrProvider && $superApp.walletEnabled"
          link-class="d-flex align-items-center"
          :to="{ name: isUser ? 'user-wallet' : isProvider ? 'provider-wallet' : isStore ? 'shop-wallet' : '' }">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/user/wallet.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Wallet') }}</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="isLoggedIn && isUserOrProvider && !isAgent" href="javascript: void(0)"
          link-class="d-flex align-items-center" @click="openReferralPopup">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/user/Referral.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Referral') }}</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="isLoggedIn && (isUserOrProvider || isStore) && !isAgent" href="javascript: void(0)"
          link-class="d-flex align-items-center" @click="openNotificationPopup">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/user/notification.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Notification') }}</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="false && isLoggedIn && isUser" link-class="d-flex align-items-center"
          :to="{ path: '/user/messaging' }">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/live_chat.jpeg')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Messages') }}</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item
          v-if="isLoggedIn && isStore"
          href="javascript: void(0)"
          link-class="d-flex align-items-center"
          :to="{ name: 'shop-messaging' }"
        >
          <b-img
            :src="require('@/assets/images/app-icons/chat.png')"
            class="mr-50 profile-img-height"
          />
          <span>{{ $t('Chats') }}</span>
        </b-dropdown-item> -->

        <b-dropdown-item v-if="isLoggedIn && isStore && isMobileView" href="javascript: void(0)"
          link-class="d-flex align-items-center" :to="{ path: '/shop/payment-config' }">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/settings_shop.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Settings') }}</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="isLoggedIn && isProvider && !isAgent && isMobileView" href="javascript: void(0)"
          link-class="d-flex align-items-center" :to="{ path: '/provider/payment-config' }">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/settings_shop.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Settings') }}</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item
          v-if="isLoggedIn && isProvider && !isAgent && isMobileView"
          href="javascript: void(0)"
          link-class="d-flex align-items-center"
          :to="{ path: '/provider/requests' }"
        >
          <b-img
            :src="require('@/assets/images/app-icons/notification.png')"
            class="mr-50 profile-img-height"
          />
          <span>{{ $t('Alerts') }}</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item
          v-if="isLoggedIn && isStore && isMobileView"
          href="javascript: void(0)"
          link-class="d-flex align-items-center"
          :to="{ path: '/shop/dashboard' }"
        >
          <b-img
            :src="require('@/assets/images/app-icons/notification.png')"
            class="mr-50 profile-img-height"
          />
          <span>{{ $t('Alerts') }}</span>
        </b-dropdown-item> -->

        <b-dropdown-item v-if="isMobileView" id="language-selection-dropdown" href="javascript: void(0)"
          link-class="d-flex align-items-center">
          <locale :noIcon="true" />
        </b-dropdown-item>
        <b-dropdown-item v-if="isLoggedIn && (isProvider || isUser || isStore) && !isAgent"
          link-class="d-flex align-items-center"
          :to="{ name: isProvider ? 'provider-support' : (isStore ? 'shop-support' : (isUser ? 'user-support' : '')) }">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/business-and-trade.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Support') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider v-if="isLoggedIn" />

        <b-dropdown-item v-if="isLoggedIn" link-class="d-flex align-items-center" @click="logout">
          <!-- <b-img
            :src="require('@/assets/images/app-icons/user/logout.png')"
            class="mr-50 profile-img-height"
          /> -->
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal id="enlarge-modal" v-model="showEnlarged" :no-close-on-backdrop="true" title="" hide-footer
      @hidden="showEnlarged = false">
      <p class="text-right">
        <a class="btn btn-primary" href="javascript: void(0)" @click="$helpers.downloadFile(this, enlargedLink)">
          <feather-icon icon="DownloadCloudIcon" size="21" />
        </a>
      </p>
      <img :src="enlargedLink" style="max-width: 90%;">
    </b-modal>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BFormCheckbox, BDropdownItem, BNavItemDropdown, BAvatar, BDropdownDivider, BImg, BModal } from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
import OnGoingService from '@/@core/layouts/components/app-navbar/components/OnGoingService.vue'
import Referral from '@/@core/layouts/components/app-navbar/components/Referral.vue'
import UserNotifications from '@/@core/layouts/components/app-navbar/components/UserNotifications.vue'
import { isUserLoggedIn } from '@/auth/utils'
import ShopNavItems from '@/views/store/shared/ShopNavItems.vue'
import AgentNavItems from '@/views/provider/agent/AgentNavItems.vue'
import { useProviderUi } from '@/views/provider/useProvider'
import eventBus from '@/libs/event-bus'
import ProviderNavItems from '@/views/provider/shared/ProviderNavItems.vue'
import UserNavItems from '@/views/users/shared/UserNavItems.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import UserAddresses from '@/@core/layouts/shared/UserAddresses.vue'
import { useStoreUi } from '@/views/store/useStore'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '@/views/users/useUser'
import LocationBar from './LocationBar.vue'
import { mapActions } from 'vuex'
import { isEmpty } from '@/utilities'
export default {
  components: {
    BImg,
    BLink,
    LocationBar,
    UserAddresses,
    BFormCheckbox,
    BNavbarNav,
    BDropdownItem,
    OnGoingService,
    BModal,
    Referral,
    UserNavItems,
    // Navbar Components
    // DarkToggler,
    CartDropdown,
    AgentNavItems,
    UserNotifications,
    BNavItemDropdown,
    BAvatar,
    BDropdownDivider,
    ShopNavItems,
    ProviderNavItems,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      showEnlarged: false,
      enlargedLink: '',
      locationInfo: {
        latitude: null,
        longitude: null,
      },
      isAgentOnline: true,
      userCurrentLocation: '',
      getUserInfo: {},
      timerForUpdateLocation: null
    }
  },
  computed: {
    isMobileView() {
      return this.$store.getters['app/windowSize'] <= 767
    },
    isLoggedIn() {
      return isUserLoggedIn()
    },
    isUser() {
      return window.SuperApp.getters.isUser()
    },
    isAgent() {
      return window.SuperApp.getters.isAgent()
    },
    isProvider() {
      return window.SuperApp.getters.isProvider()
    },
    isAdmin() {
      return window.SuperApp.getters.isAdmin()
    },
    isStore() {
      return window.SuperApp.getters.isStore()
    },
    isShopUrl() {
      return this.$route.path.startsWith('/shop')
    },
    isProviderUrl() {
      return this.$route.path.startsWith('/provider')
    },
    isVisible() {
      return this.isUser || this.isProvider || this.isStore
    },
    isUserOrProvider() {
      return this.isUser || this.isProvider
    },
  },
  mounted() {
    this.setUserInfo()
    setTimeout(() => {
      this.setUserInfo()
    }, 1000)
    if (this.isLoggedIn && (this.isAgent || this.isProvider || this.isStore || this.isUser)) {
      this.isAgentOnline = Boolean(window.SuperApp.getters.userInfo().is_online)
      if (this.isUser) {
        this.isAgentOnline = Boolean(window.SuperApp.getters.userInfo().online)
      }
    }
    eventBus.$on('logout-user', () => {
      this.logout()
    })
    eventBus.$on('show-enlarged', link => {
      this.enlargedLink = link
      this.showEnlarged = true
    })
    eventBus.$on('location-bar-component', payload => {
      if (payload) {
        if (payload.type === 'showAddressSelection') {
          this.showAddressSelection()
        } else if (payload.type === 'setPlace') {
          this.setPlace(payload.data)
        } else if (payload.type === 'selectCurrentLocation') {
          this.selectCurrentLocation()
        } else if (payload.type === 'resetSearch') {
          this.resetSearch()
        }
      }
    })
    eventBus.$on('userLocationChanged', this.setLocation)
    eventBus.$on('userLocationChangedByMap', this.setLocationByName)
    if (window.SuperApp.getters.isAgent()) {
      this.updateLocation();
      this.timerForUpdateLocation = setInterval(() => {
        this.updateLocation();
      }, 300000);
    }
    eventBus.$on('set-user-info-force', () => {
      window.SuperApp.getters.setLang(window.SuperApp.getters.userInfo().language)
      this.setUserInfo()
    })
    this.setLocation()
  },
  destroyed() {
    eventBus.$off('set-user-info-force')
    eventBus.$off('location-bar-component')
    eventBus.$off('show-enlarged')
    eventBus.$off('userLocationChanged', this.setLocation)
    eventBus.$off('userLocationChangedByMap', this.setLocationByName)
    eventBus.$off('logout-user')
  },
  methods: {
    ...mapActions('shopAgent', [
      'changeOnlineStatus'
    ]),
    setUserInfo() {
      if (!window.SuperApp.getters.userInfo()) this.getUserInfo = {}
      this.getUserInfo = window.SuperApp.getters.userInfo()
      eventBus.$emit('set-language-locale', window.SuperApp.getters.selectedLang())
    },
    async updateLocation(force, byTracker) {
      let isUpdated = false;
      if ('geolocation' in navigator) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.watchPosition(
            position => {
              if (!force) {
                if (parseFloat(this.locationInfo.latitude) == parseFloat(position.coords.latitude) && parseFloat(this.locationInfo.longitude) == parseFloat(position.coords.longitude)) return resolve(true)
              }
              const { updateProviderLocation } = useProviderUi()
              const agent_on_login = localStorage.getItem('agent_on_login');
              if (agent_on_login) {
                localStorage.removeItem('agent_on_login')
              }
              const agent_on_logout = localStorage.getItem('agent_on_logout');
              if (agent_on_logout) {
                localStorage.removeItem('agent_on_logout')
              }
              const formData = new FormData()
              if (!isEmpty(byTracker)) {
                formData.append('tracker_on', byTracker.tracker_on)
                formData.append('tracker_off', byTracker.tracker_off)
              }else{
                formData.append('on_login', !isEmpty(agent_on_login) ? agent_on_login : 0)
                formData.append('on_logout', !isEmpty(agent_on_logout) ? agent_on_logout : 0)
              }
              formData.append('latitude', position.coords.latitude)
              formData.append('longitude', position.coords.longitude)
              if(isUpdated) return resolve(true)
              updateProviderLocation(formData).then(({ data }) => {
                if (data.statusCode === '200') {
                  this.locationInfo.latitude = position.coords.latitude
                  this.locationInfo.longitude = position.coords.longitude
                  isUpdated = true
                  return resolve(true)
                }
                return resolve(true)
              }).catch(() => {
                return resolve(true)
              })
            },
            error => {
              return resolve(true)
            },
          )
        });
      }
      return new Promise((resolve, reject) => resolve(true));
    },
    hideNavBar($event) {
      setTimeout(() => {
        if (document.querySelector('#language-selection-dropdown .dropdown-language.show')) {
          if ($event.target) {
            if ($event.target.parentElement) {
              $event.target.parentElement.classList.add('show')
            }
            $event.target.classList.add('show')
          }
        }
      }, 25)
    },
    selectCurrentLocation() {
      if (this.$refs.userAddressRef) {
        this.$refs.userAddressRef.selectCurrentLocation()
      }
    },
    setNewAddress(address) {
      this.userCurrentLocation = address
      eventBus.$emit('location-bar-componet-set', {
        data: this.userCurrentLocation,
        type: 'userCurrentLocation',
      })
    },
    showAddressSelection() {
      if (this.$refs.userAddressRef && this.$refs.userAddressRef.showSelectAddress) {
        this.$refs.userAddressRef.showSelectAddress()
      }
    },
    setLocation() {
      this.userCurrentLocation = window.SuperApp.actions.selectedCityName()
      eventBus.$emit('location-bar-componet-set', {
        data: this.userCurrentLocation,
        type: 'userCurrentLocation',
      })
    },
    setLocationByName(payload) {
      this.userCurrentLocation = payload
      eventBus.$emit('location-bar-componet-set', {
        data: this.userCurrentLocation,
        type: 'userCurrentLocation',
      })
    },
    getStoreInfo() {
      showLoader()
      const { storeProfile } = useStoreUi()

      storeProfile(window.SuperApp.getters.userInfo().id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            window.SuperApp.getters.setUserInfo(JSON.stringify(data.responseData))
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    userProfileInfo() {
      const { userProfile } = useUserUi()
      userProfile()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            window.SuperApp.getters.setUserInfo(JSON.stringify(data.responseData))
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getProviderProfile() {
      showLoader()
      const { providerProfile } = useProviderUi()

      providerProfile(window.SuperApp.getters.userInfo().id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            window.SuperApp.getters.setUserInfo(JSON.stringify(data.responseData))
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    toggleOnlineOffline() {
      if (window.SuperApp.getters.userInfo() && window.SuperApp.getters.isStore() && !window.SuperApp.validations.isShopDetailValid()) {
        showDangerNotification(this, 'Please fill the required details to go online')
        this.$nextTick(() => {
          this.isAgentOnline = false
        })
        return
      }
      if (window.SuperApp.getters.userInfo() && window.SuperApp.getters.isProvider() && !window.SuperApp.validations.isProviderDetailValid()) {
        showDangerNotification(this, 'Please fill the required details to go online')
        this.$nextTick(() => {
          this.isAgentOnline = false
        })
        return
      }
      this.$swal({
        title: !this.isAgentOnline ? this.$t('You will stop receiving request') : this.$t('Go online to start receiving request'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.agentStatusChanged()
        } else {
          this.isAgentOnline = !this.isAgentOnline
        }
      })
    },
    async agentStatusChanged() {
      console.log("Status update function called")
      console.log(this.isAgent, 'this.isAgent')
      if (this.isStore) {
        const { changeShopStatus } = useStoreUi()
        showLoader()
        changeShopStatus(Number(this.isAgentOnline))
          .then(({ data }) => {
            hideLoader()
            if (data.statusCode === '200') {
              showSuccessNotification(this, data.message)
              this.getStoreInfo()
            } else {
              showDangerNotification(this, data.message)
            }
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      } else if (this.isUser) {
        const { changeUserStatus } = useUserUi()
        showLoader()
        const formData = new FormData()
        formData.append('online', Number(this.isAgentOnline))
        changeUserStatus(formData)
          .then(({ data }) => {
            hideLoader()
            if (data.statusCode === '200') {
              showSuccessNotification(this, data.message)
              this.userProfileInfo()
            } else {
              showDangerNotification(this, data.message)
            }
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      } else if (this.isProvider && !this.isAgent) {
        const { changeProviderStatus } = useProviderUi()
        showLoader()
        changeProviderStatus(Number(this.isAgentOnline))
          .then(({ data }) => {
            hideLoader()
            if (data.statusCode === '200') {
              showSuccessNotification(this, data.message)
              this.getProviderProfile()
            } else {
              showDangerNotification(this, data.message)
            }
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      } else if (this.isAgent) {
        showLoader()
        const status = Number(this.isAgentOnline)
        const byTracker = {
          tracker_on: status === 1 ? 1 : 0,
          tracker_off: status === 0 ? 1 : 0
        }        
        console.log("updateLocation => started", Number(new Date))
        const res = await this.updateLocation(true, byTracker);
        console.log("updateLocation => ended", Number(new Date))
        
        this.changeOnlineStatus(this.isAgentOnline)
        .then(({ message, responseData }) => {
          hideLoader()
          showSuccessNotification(this, message)
          this.isAgentOnline = Boolean(responseData.agent.is_online)
        })
        .catch(error => {
          hideLoader()
          showErrorNotification(this, error)
        })

      }
      eventBus.$emit('agent-status-changed', this.isAgentOnline)
    },
    maximizeScreen() {
      eventBus.$emit('open-full-screen-view')
    },
    setPlace(payload) {
      eventBus.$emit('location-searched', payload)
    },
    resetSearch() {
      this.userCurrentLocation = ''
      eventBus.$emit('location-bar-componet-set', {
        data: this.userCurrentLocation,
        type: 'userCurrentLocation',
      })
      eventBus.$emit('location-searched', {
        isReset: true,
      })
    },
    openReferralPopup() {
      this.$refs.referralComponent.getReferralInfo()
    },
    openNotificationPopup() {
      if (this.isStore && this.isLoggedIn) {
        this.$router.push({
          path: '/shop/notifications',
        })
      } else if (this.isProvider && !this.isAgent && this.isLoggedIn) {
        this.$router.push({
          path: '/provider/notifications',
        })
      } else {
        this.$refs.userNotificationComponent.getUserNotifications()
      }
    },
    async logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      /*
      if (window.SuperApp.getters.isAgent()) {
        localStorage.setItem('agent_on_logout', 1)
        this.updateLocation(true).then(() => {
          this.finalLogout()
        }).catch(() => {
          localStorage.removeItem('agent_on_logout')
          this.finalLogout()
        })
      }
      */
      this.finalLogout()
    },
    async finalLogout() {
      localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('TokenKeyName'))
      localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('RefreshTokenKeyName'))
      // Remove userData from localStorage
      window.SuperApp.getters.removeUserInfo()
      window.SuperApp.getters.removeUserAccess()

      // Redirect to login page
      if (window.SuperApp.getters.isUser()) this.$router.push({ name: 'apps-login' })
      else if (window.SuperApp.getters.isProvider()) this.$router.push({ name: 'apps-provider-login' })
      else if (window.SuperApp.getters.isStore()) this.$router.push({ name: 'apps-shop-login' })
      else if (window.SuperApp.getters.isAdmin()) this.$router.push({ name: 'apps-admin-login' })
    }
  },
}
</script>

<style>
#enlarge-modal .modal-body {
  max-height: 80vh;
  overflow: auto;
}
</style>

<style scoped>
.feather-icon-wrapper {
  margin: 0px 10px;
  position: relative;
}

.profile-img-height {
  height: 16px;
}

.navbar-header-logo-image {
  width: 40px;
  height: 40px;
}

.page-name-title {
  position: absolute;
  left: 25px;
  font-size: 20px;
  color: #000;
}

@media only screen and (max-width: 1024px) {
  .page-name-title {
    left: 85px;
    transform: unset;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-header-logo-image {
    width: 24px;
    height: 24px;
  }

  .cursor-pointer.maximize-icon-wrapper {
    margin-right: 0 !important;
  }

  .mt-m9-sm {
    margin-top: -6px
  }
}
</style>
