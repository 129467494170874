<template>
  <div>
    <p class="cursor-pointer cp m-0 p-0" @click="toggleAddress = !toggleAddress">
        {{ deliveryAddress.map_address }} <feather-icon :icon="toggleAddress ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="15" />
    </p>
    <p v-if="toggleAddress" class="m-0 p-0">
        {{ $t('Flat No') }}: {{ deliveryAddress.flat_no }}
    </p>
    <p v-if="toggleAddress" class="m-0 p-0">
        {{ $t('Building/Villa Name') }}: {{ deliveryAddress.building }}
    </p>
    <p v-if="toggleAddress" class="m-0 p-0">
        {{ $t('Street') }}: {{ deliveryAddress.street }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'AgentDeliveryAddress',
  props: {
    deliveryAddress: Object,
  },
  data: () => ({
    toggleAddress: false
  })
}
</script>