/* eslint-disable global-require */
const appImageUrlPrefix = `${process.env.VUE_APP_API_BASE_END_POINT}/images`
export default [
  {
    title: 'Dashboard',
    route: 'provider-agent-dashboard',
    image: require('@/assets/images/app-icons/Dashboard.png'),
  },
  {
    title: 'Requests',
    route: 'provider-agent-requests',
    image: require('@/assets/images/app-icons/exchange.png'),
  },
  {
    title: 'Schedule',
    route: 'agent-schedules',
    image: `${appImageUrlPrefix}/icons/schedule-48.png`,
  },
  {
    title: 'Profile',
    route: 'provider-profile',
    image: require('@/assets/images/app-icons/sub-menu/profile.png'),
  },
  // {
  //   title: 'Settings',
  //   image: require('@/assets/images/app-icons/settings.png'),
  //   children: [
  //     {
  //       title: 'Profile',
  //       route: 'provider-profile',
  //       image: require('@/assets/images/app-icons/sub-menu/profile.png'),
  //     },
  //   ],
  // },
  {
    title: 'About',
    image: `${appImageUrlPrefix}/icons/about.svg`,
    children: [
      {
        title: 'Terms of Use',
        route: 'terms-and-condition',
        image: `${appImageUrlPrefix}/icons/terms-and-conditions.svg`,
      },
      {
        title: 'Privacy Policy',
        route: 'privacy-policy',
        image: `${appImageUrlPrefix}/icons/privacy-policy.svg`,
      },
    ],
  },
]
