<template>
  <div class="chat">
    <template v-if="targetUser">
      <div class="chat__header">
        <span class="chat__header__greetings d-flex align-items-center">
          <b-avatar size="40" variant="primary" :src="`${$apiUrl}${targetUser.picture}`" class="mr-1" />
          <div>
            <div>{{ targetUser.name }}</div>
            <div v-if="order">
              <small class="mt-1 text-light">
                {{ !isEmpty(order.store_order_invoice_id) ? `Order: ${order.store_order_invoice_id}` : `Service:
                ${order.booking_id}` }}
              </small>
            </div>
          </div>
        </span>
      </div>
      <chat-list :chats="eligibleChats" :thisUser="thisUser" :targetUser="targetUser" />
      <chat-form @submitMessage="sendMessage" />
    </template>
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'
import { useUserUi } from '@/views/users/useUser'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import ChatList from './ChatList.vue'
import ChatForm from './ChatForm.vue'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty, thisChatUser } from '@/utilities'
export default {
  components: {
    ChatList,
    ChatForm,
  },
  props: {
    admin_service: {
      type: String,
      default: '',
    },
    orderable_id: {
      type: [String, Number],
      default: '',
    },
    to_userable_type: {
      type: String,
      default: '',
    },
    to_userable_id: {
      type: [String, Number],
      default: '',
    },
    display_name: {
      type: String,
      default: '',
    },
    modalState: {
      type: Boolean,
      default: () => false,

    },
  },
  data() {
    return {
      targetUser: null,
      thisUser: null,
      chats: [],
      order: null,
    }
  },
  computed: {
    ...mapGetters('chat', ['chatOrders']),
    eligibleChats: {
      get() {
        if (!isEmpty(this.admin_service) && !isEmpty(this.orderable_id)) {
          return this.chats.filter(i => i.admin_service == this.admin_service && i.orderable_id == this.orderable_id)
        }
        return this.chats
      }
    }
  },
  mounted() {
    this.thisUser = thisChatUser()
    eventBus.$on('user-messaged', this.messageArrived)
    this.getData()
  },
  beforeMount() {
    eventBus.$off('user-messaged', this.messageArrived)
  },
  methods: {
    isEmpty,
    ...mapActions('chat', [
      "getChats",
      "getOrders",
      "getOrderChats",
      "chatStore",
      "markReadChat",
      "addToUnread",
    ]),
    scrollDown() {
      setTimeout(() => {
        const element = document.getElementById('chat__body')
        if (element) {
          element.scrollTop = element.scrollHeight
          const payload = {
            admin_service: this.admin_service,
            orderable_id: this.orderable_id,
            from_userable_type: this.thisUser.type,
            from_userable_id: this.thisUser.id,
            to_userable_type: this.to_userable_type,
            to_userable_id: this.to_userable_id,
          }
          this.markReadChat(payload)
        }
      }, 100);
    },
    messageArrived(chat) {
      if (
        chat.to_userable_type == this.thisUser.type
        && chat.to_userable_id == this.thisUser.id
        && chat.from_userable_type == this.targetUser.type
        && chat.from_userable_id == this.targetUser.id
      ) {
        this.chats.push(chat);
        this.scrollDown();
      } else if (
        chat.from_userable_type == this.thisUser.type
        && chat.from_userable_id == this.thisUser.id
        && chat.to_userable_type == this.targetUser.type
        && chat.to_userable_id == this.targetUser.id
      ) {
        this.chats.push(chat);
        this.scrollDown();
      }
    },
    getData() {
      showLoader()
      let getDataFn = !isEmpty(this.admin_service) ? this.getOrderChats : this.getChats
      const payload = {
        admin_service: this.admin_service,
        orderable_id: this.orderable_id,
        to_userable_type: this.to_userable_type,
        to_userable_id: this.to_userable_id,
      }
      getDataFn(payload).then(r => {
        this.chats = r.chats
        this.order = r.order
        this.targetUser = r.target_user
        hideLoader()
        this.scrollDown()
      }).catch(e => {
        hideLoader()
        console.error(e)
      })
    },
    async sendMessage(message) {
      const payload = {
        admin_service: this.admin_service,
        orderable_id: this.orderable_id,
        to_userable_type: this.to_userable_type,
        to_userable_id: this.to_userable_id,
        message: typeof message === 'string' ? message : null,
        attachment: typeof message !== 'string' && message.picture ? message.picture : null,
      }
      this.chatStore(payload).then(chat => {
        this.chats.push(chat);
        this.refreshOrderList(chat)
        eventBus.$emit('chat-message', chat)
      }).catch(e => {

      })
      this.scrollDown()
    },
    refreshOrderList(chat) {
      if (!this.chatOrders.some(i => i.admin_service == chat.admin_service && i.orderable_id == chat.orderable_id)) {
        this.getOrders();
      }
    }
  },
  watch: {
    modalState: {
      handler: function (val) {
        if (val) {
          this.scrollDown()
        }
      }
    }
  }
}
</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat__header {
  background: #7367f0;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 24px 24px 0px 0px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 700;
}

.chat__header__greetings {
  color: #fff;
}
</style>
