<template>
    <span class="feather-icon-wrapper mobile-static-position">
        <b-nav-item-dropdown class="dropdown-cart mr-25 d-inline-block" menu-class="dropdown-menu-media" right>
            <template #button-content>
                <span class="badge badge-up badge-pill badge-primary">{{ unreadChats.length }}</span>
                <img :src="require('@/assets/images/app-icons/chat.png')" width="21">
            </template>

            <!-- Header -->
            <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex pb-50 justify-content-between">
                    <h4 class="notification-title mb-0 mr-auto">
                        {{ $t('Messages') }}
                    </h4>
                    <!-- <b-link :to="{
                        path: '/shop/messaging',
                    }">
                        <b-badge pill variant="light-primary">
                            {{ $t('View All') }}
                        </b-badge>
                    </b-link> -->
                </div>
            </li>

            <!-- Cart Items Service -->
            <li v-if="!isEmpty(chatOrders)"
                class="scrollable-container media-list scroll-area ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
                tagname="li">
                <div v-for="(chat, index) of chatOrders" :key="index" class="chat-nav-section-wrapper border-bottom">
                    <div class="px-2 py-1 bg-noti-item">
                        <div>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex justify-content-start align-content-center align-items-center">
                                    <div class="mr-1">
                                        <img :src="`${$apiUrl}/images/icons/order.svg`" width="30" />
                                    </div>
                                    <div>
                                        <span class="mb-0 my-0 font-weight-bolder fs-1rem">
                                            {{ chat.order_id }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="chat.payment_mode"
                                    class="d-flex justify-content-start align-content-center align-items-center">
                                    <img :src="statusImages[chat.payment_mode]" width="30" />
                                </div>
                                <div v-if="chat.order_type"
                                    class="d-flex justify-content-start align-content-center align-items-center">
                                    <div
                                        class="d-flex align-items-center justify-content-center text-center wrap-dashboard-card-options width-fc custom-icons">
                                        <img :src="statusImages[chat.order_type]" />
                                        <p class="mb-0 font-12">{{ chat.order_type }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mt-1">
                                <div v-if="chat.chats.provider" class="mr-1">
                                    <b-button variant="outline-dark" class="small_btn" size="sm"
                                        @click="setChatInfo(chat, chat.chats.provider)">
                                        <b-img height="25" class="cmr-1" :src="$helpers.getIcons('provider')" />
                                        {{ $t('Provider') }}
                                    </b-button>
                                    <b-badge
                                        v-if="!isEmpty(unreadChatsByOrder(chat.admin_service, chat.id, chat.chats.provider))"
                                        class="top-right-badge" variant="danger" pill>{{
                                            unreadChatsByOrder(chat.admin_service,
                                                chat.id, chat.chats.provider).length }}</b-badge>
                                </div>
                                <div v-if="chat.chats.store" class="mr-1">
                                    <b-button variant="outline-dark" class="small_btn" size="sm"
                                        @click="setChatInfo(chat, chat.chats.store)">
                                        <b-img height="25" class="cmr-1" :src="$helpers.getIcons('shop')" />
                                        {{ $t('Store') }}
                                    </b-button>
                                    <b-badge
                                        v-if="!isEmpty(unreadChatsByOrder(chat.admin_service, chat.id, chat.chats.store))"
                                        class="top-right-badge" variant="danger" pill>{{
                                            unreadChatsByOrder(chat.admin_service,
                                                chat.id, chat.chats.store).length }}</b-badge>
                                </div>
                                <div v-if="chat.chats.user" class="mr-1">
                                    <b-button variant="outline-dark" class="small_btn" size="sm"
                                        @click="setChatInfo(chat, chat.chats.user)">
                                        <b-img height="25" class="cmr-1" :src="$helpers.getIcons('user')" />
                                        {{ $t('User') }}
                                    </b-button>
                                    <b-badge
                                        v-if="!isEmpty(unreadChatsByOrder(chat.admin_service, chat.id, chat.chats.user))"
                                        class="top-right-badge" variant="danger" pill>{{
                                            unreadChatsByOrder(chat.admin_service,
                                                chat.id, chat.chats.user).length }}</b-badge>
                                </div>
                                <div v-if="chat.chats.agent">
                                    <b-button variant="outline-dark" class="small_btn" size="sm"
                                        @click="setChatInfo(chat, chat.chats.agent)">
                                        <b-img height="25" class="cmr-1" :src="$helpers.getIcons('agent')" />
                                        {{ $t('Agent') }}
                                    </b-button>
                                    <b-badge
                                        v-if="!isEmpty(unreadChatsByOrder(chat.admin_service, chat.id, chat.chats.agent))"
                                        class="top-right-badge" variant="danger" pill>{{
                                            unreadChatsByOrder(chat.admin_service,
                                                chat.id, chat.chats.agent).length }}</b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <div v-else class="p-2 text-center">{{ $t('No Messages Available') }}</div>
        </b-nav-item-dropdown>
        <b-modal 
            id="chat-user-modal-popup-navbar" 
            v-model="modals.chat.open" 
            :no-close-on-backdrop="true" 
            hide-footer
            @hidden="modals.chat.open = false"
        >
            <chat 
                :admin_service="modals.chat.admin_service" 
                :orderable_id="modals.chat.id"
                :to_userable_type="modals.chat.to_userable_type" 
                :to_userable_id="modals.chat.to_userable_id"
                :modalState="modals.chat.open" 
            />
        </b-modal>
    </span>
</template>

<script>
import Chat from '@/views/shared/chat/ChatNew.vue'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'

export default {
    name: "NavChatBar",

    components: {
        Chat
    },

    data: () => ({
        modals: {
            chat: {
                open: false,
                admin_service: null,
                id: null,
                to_userable_type: null,
                to_userable_id: null,
            },
        },
        thisUser: null,
        statusImages: {},
    }),

    computed: {
        ...mapGetters('chat', [
            'unreadChatsByOrder',
            'unreadChats',
            'chatOrders',
        ]),
    },


    methods: {
        ...mapActions('chat', ['getOrders', 'addToUnread']),
        isEmpty,
        setChatInfo(item, toData) {
            this.modals.chat = {
                admin_service: item.admin_service,
                id: item.id,
                to_userable_type: toData.to_userable_type,
                to_userable_id: toData.to_userable_id,
                open: true
            }
        },
    },

    mounted() {
        this.statusImages = {
            DELIVERY: require('@/assets/images/app-icons/agent.png'),
            WALLET: require('@/assets/images/app-icons/wallet.png'),
            'DINE IN': require('@/assets/images/app-icons/shop.png'),
            DINE_IN: require('@/assets/images/app-icons/shop.png'),
            POS: require('@/assets/images/app-icons/POS.jpeg'),
            TAKEAWAY: require('@/assets/images/app-icons/take-away.png'),
            CASH: this.$helpers.getIcons('cash'),
            CARD: this.$helpers.getIcons('card'),
            'CARD ON DELIVERY': this.$helpers.getIcons('card-on-delivery'),
            CARD_ON_DELIVERY: this.$helpers.getIcons('card-on-delivery'),
            on_delivery: this.$helpers.getIcons('card-on-delivery'),
        }
        this.getOrders()
    }
}
</script>

<style scoped>
.bg-noti-item {
  background-color: #EEE;
  margin-bottom: 5px;
  border-radius: 12px;
}
.bg-noti-item:hover {
  background-color: #CCC;
}
.top-right-badge{
	position: relative;
	top: -15px;
	right: 10px;
}
</style>