<template>
  <div class="referral-component">
    <b-modal
      v-model="viewReferralModal"
      :no-close-on-backdrop="true"
      :title="$t('Refer a friend')"
      :ok-title="$t('Invite')"
      hide-footer
      @hidden="hideReferral"
    >
      <b-col
        v-if="userData && userData.referral"
        cols="12"
        md="12"
      >
        <b-card>
          <h4>
            <span>{{ $t('Your Referral Code') }}:</span> {{ userData.referral_unique_id }}
          </h4>
          <!-- <h4><span>{{ $t('Your Referral Code') }}:</span> {{ userData.referral.referral_code }}</h4>
          <p class="mb-0">
            <span>{{ $t('Referral Count') }}:</span> {{ userData.referral.user_referral_count }}
          </p>
          <p><span>{{ $t('Referral Amount') }}:</span> {{ userData.referral.user_referral_amount }}</p> -->
        </b-card>
      </b-col>
      <p
        v-if="userData && userData.referral"
        class="font-weight-bold"
      >
        {{ $t('Invite your friends') }}
        <!-- {{ $t('Invite your friends') }} {{ userData.referral.referral_amount }} {{ userData.currency_symbol }} for every {{ userData.referral.referral_count }} Users -->
      </p>
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          :label="$t('Enter Email')"
          label-for="referralEmail"
        >
          <b-form-input
            id="referralEmail"
            v-model="referralEmail"
            type="email"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="text-right"
      >
        <button
          class="btn btn-primary mr-1"
          @click="shareReferral"
        >
          {{ $t('Share') }}
        </button>
        <button
          class="btn btn-primary"
          @click="earnReferral"
        >
          {{ $t('Invite') }}
        </button>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCol, BFormGroup, BFormInput, BCard } from 'bootstrap-vue'
import { useUserUi } from '@/views/users/useUser'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'

export default {
  components: {
    BModal,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
  },
  data() {
    return {
      viewReferralModal: false,
      referralEmail: '',
      userData: null,
    }
  },
  methods: {
    hideReferral() {
      this.viewReferralModal = false
    },
    earnReferral() {
      window.open(
        `mailto:${this.referralEmail}?subject=Invitation to join MOM&body=Hi,%0A%0A I found this website and thought you might like it. Use my referral code(${this.userData.referral_unique_id}) on registering in the application.%0A%0AWebsite: ${window.location.origin}/user/login %0AReferral Code:${this.userData.referral_unique_id}`,
      )
    },
    shareReferral() {
      window.SuperApp.actions.sharePage('MOM', '', window.location.origin)
    },
    getReferralInfo() {
      if (this.userData != null && this.userData.referral == null) {
        this.userData = null
      }
      if (this.userData === null) {
        const { userProfile } = useUserUi()
        showLoader()
        userProfile()
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.userData = data.responseData
              this.viewReferralModal = true
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      } else {
        this.viewReferralModal = true
      }
    },
  },
}
</script>

<style scoped></style>
