<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-1"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <span class="font-weight-bold">
        <img :src="require('@/assets/images/app-icons/active-94.png')" alt="" height="32" />
      </span>
      <feather-icon
        :badge="onGoingServices.length"
        class="text-body"
        icon="''"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex pb-50">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Active') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ onGoingServices.length }} {{ $t('Items') }}
        </b-badge>
      </div>
      <div class="col-md-12 pt-0">
        <b-form-group
          label-for="searchService"
        >
          <b-form-input
            id="searchService"
            v-model="searchServices"
            :placeholder="$t('Search')"
            @keyup="filterServices"
          />
        </b-form-group>
      </div>
    </li>

    <!-- Cart Items Service -->
    <li
      v-if="onGoingServices.length > 0"
      class="ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
    >
      <b-media
        v-for="product in filteredOnGoingServices"
        :key="product.id"
      >
        <div class="d-flex justify-content-between">
          <h6 class="mb-0 text-center ongoing-service-title mb-0">
            {{ product.service.admin_service }}
          </h6>
          <b-badge
            pill
            variant="light-primary"
            class="cursor-pointer"
            style="height: 20px;"
            @click="goToService(product)"
          >
            {{ $t('View') }}
          </b-badge>
        </div>
        <p
          v-if="product.service.admin_service === 'ORDER' && product.request.pickup"
          class="mb-25"
        >
          <span class="font-weight-bold">{{ $t('Shop Name') }}:</span> {{ product.request.pickup.store_name }}
        </p>
        <p
          v-if="product.service.admin_service === 'ORDER'"
          class="mb-25"
        >
          <span class="font-weight-bold">{{ $t('Booking ID') }}:</span> {{ product.request.store_order_invoice_id }}
        </p>
        <p
          v-if="product.service.admin_service === 'SERVICE'"
          class="mb-25"
        >
          <span class="font-weight-bold">{{ $t('Service Location') }}:</span> {{ product.request.s_address }}
        </p>
        <p
          v-if="product.service.admin_service === 'SERVICE'"
          class="mb-25"
        >
          <span class="font-weight-bold">{{ $t('Booking ID') }}:</span> {{ product.request.booking_id }}
        </p>
        <p
          v-if="product.service.admin_service === 'TRANSPORT'"
          class="mb-25"
        >
          <span class="font-weight-bold">{{ $t('From') }}:</span> {{ product.request.s_address }}
        </p>
        <p
          v-if="product.service.admin_service === 'TRANSPORT'"
          class="mb-25"
        >
          <span class="font-weight-bold">{{ $t('To') }}:</span> {{ product.request.d_address }}
        </p>
        <p class="mb-25">
          <span class="font-weight-bold">{{ $t('Status') }}:</span> {{ product.service_request ? product.service_request.status : product.order ? product.order.status : product.request.status }}
        </p>
      </b-media>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { BNavItemDropdown, BBadge, BMedia, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUserUi } from '@/views/users/useUser'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import { isUserLoggedIn } from '@/auth/utils'
import eventBus from '@/libs/event-bus'
import { isEmpty } from '@/utilities'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      isCallInProgress: false,
      searchServices: '',
      filteredOnGoingServices: [],
    }
  },
  computed: {
    onGoingServices() {
      return this.$store.state['app-ecommerce'].onGoingServices
    },
  },
  mounted() {
    eventBus.$on('refresh-on-going-services', this.forceRefresh)
    this.searchServices = ''
    this.getUserOnGoingServices()
  },
  destroyed() {
    eventBus.$off('refresh-on-going-services', this.forceRefresh)
  },
  methods: {
    forceRefresh() {
      this.$store.commit('app-ecommerce/UPDATE_ONGOING_SERVICES', [])
      this.getUserOnGoingServices()
    },
    getUserOnGoingServices() {
      if (!isUserLoggedIn()) {
        return
      }
      if (this.onGoingServices.length === 0 && !this.isCallInProgress) {
        this.isCallInProgress = true
        const { userOnGoingServices } = useUserUi()
        userOnGoingServices()
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.$store.commit('app-ecommerce/UPDATE_ONGOING_SERVICES', data.responseData)
              this.filterServices()
            }
            this.isCallInProgress = false
          })
          .catch(error => {
            this.isCallInProgress = false
            showErrorNotification(this, error)
          })
      } else {
        this.filterServices()
        this.isCallInProgress = false
      }
    },
    filterServices() {
      if (this.searchServices) {
        this.filteredOnGoingServices = this.onGoingServices.filter(i => !isEmpty(i.request) && !isEmpty(i.service)).filter(
          p =>
            (p.service && p.service.admin_service === 'ORDER' && p.request && p.request.pickup && p.request.pickup.store_name && p.request.pickup.store_name.toLowerCase().includes(this.searchServices.toLowerCase())) ||
            (p.service && p.service.admin_service === 'ORDER' && p.request && p.request.store_order_invoice_id && p.request.store_order_invoice_id.toLowerCase().includes(this.searchServices.toLowerCase())) ||
            (p.service && p.service.admin_service === 'SERVICE' && p.request && p.request.s_address && p.request.s_address.toLowerCase().includes(this.searchServices.toLowerCase())) ||
            (p.service && p.service.admin_service === 'SERVICE' && p.request && p.request.booking_id && p.request.booking_id.toLowerCase().includes(this.searchServices.toLowerCase())) ||
            (p.service && p.service.admin_service === 'TRANSPORT' && p.request && p.request.s_address && p.request.s_address.toLowerCase().includes(this.searchServices.toLowerCase())) ||
            (p.service && p.service.admin_service === 'TRANSPORT' && p.request && p.request.d_address && p.request.d_address.toLowerCase().includes(this.searchServices.toLowerCase())) ||
            (p.request && p.request.status && p.request.status.toLowerCase().includes(this.searchServices.toLowerCase())) ||
            (p.service && p.service.admin_service && p.service.admin_service.toLowerCase().includes(this.searchServices.toLowerCase())),
        )
      } else {
        this.filteredOnGoingServices = this.onGoingServices.filter(i => !isEmpty(i.request) && !isEmpty(i.service))
      }
    },
    goToService(product) {
      if (product.service.admin_service === 'ORDER') {
        this.$router.push({
          path: `/user/store/order/${product.request.id}`,
        })
        if (window.location.href.includes('/user/store/order/')) {
          eventBus.$emit('new-order-viewed', product.request.id)
        }
        this.isCustomizerOpen = false
      } else if (product.service.admin_service === 'SERVICE') {
        this.$router.push({
          path: `/user/service/request/${product.request.id}`,
        })
        this.isCustomizerOpen = false
      } else if (product.service.admin_service === 'TRANSPORT') {
        window.location.href = `/app/user/ride/${product.company_id}/transport/?id=${product.request.id}`
        this.isCustomizerOpen = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media img {
  width: 100%;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-body {
  display: block !important;
}
</style>
