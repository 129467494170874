<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[{ expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }, skin === 'semi-dark' ? 'menu-dark' : 'menu-light']"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <ul class="nav navbar-nav flex-row">
        <!-- Logo & Text -->
        <li class="nav-item mr-auto">
          <b-link
            class="navbar-brand"
            :to="redirectUrl"
          >
            <span class="brand-logo">
              <b-img
                style="max-width: 40px"
                :src="require('@/assets/images/logo/mom-logo-icon-72.png')"
                alt="logo"
              />
            </span>
            <!-- <h2 class="brand-text">
               <b-img
                  style="width: 50%"
                  :src="require('@/assets/images/app-images/logo-text.jpeg')"
                />
              </h2> -->
          </b-link>
        </li>

        <!-- Toggler Button -->
        <!-- <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li> -->
      </ul>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items
        :items="menuToRender"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import providerNavMenuItems from '@/navigation/vertical/provider'
import adminNavMenuItems from '@/navigation/vertical/admin'
import agentMenuItems from '@/navigation/vertical/agent'
import shopNavMenuItems from '@/navigation/vertical/shop'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { isUserLoggedIn } from '@/auth/utils'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { isEmpty } from '@/utilities';

const mapAndFilterChild = (_items, is_parent_store) => {
  return _items.map(i => {
    if(!isEmpty(i.children)){
      i.children = filterByAccessLevel(i.children, is_parent_store);
    }
    return i;
  }).filter(i => !isEmpty(i.route) || !isEmpty(i.children));
}

const filterByAccessLevel = (_items, is_parent_store) => {
  if(is_parent_store){
    return mapAndFilterChild(_items, is_parent_store).filter(i => i.accessLevel != 2)
  }
  return mapAndFilterChild(_items, is_parent_store).filter(i => i.accessLevel != 1)
}
export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    const redirectUrl = computed(() => {
      if(window.SuperApp.getters.isAdmin()){
        return '/admin/cmspages/faq'
      }else if(window.SuperApp.getters.isAgent()){
        return '/provider/agent/dashboard'
      }else if(window.SuperApp.getters.isProvider()){
        return '/provider/requests'
      }else if(window.SuperApp.getters.isStore()){
        return '/shop/dashboard'
      }
      return '/user/dashboard'
    })

    // App Name
    const { appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      providerNavMenuItems,
      adminNavMenuItems,
      agentMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      redirectUrl,
      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appLogoImage,
    }
  },
  computed: {
    shopNavMenuItems: {
      get(){
        const userInfoJson = localStorage.getItem('shopData')
        const userInfo = !isEmpty(userInfoJson) ? JSON.parse(userInfoJson) : {}
        const is_parent_store = userInfo?.is_parent_store ?? false;
        const finalItems = filterByAccessLevel(shopNavMenuItems, is_parent_store)
        return finalItems
      }
    },
    menuToRender() {
      if (!isUserLoggedIn()) return []
      if (window.SuperApp.getters.isUser()) return this.navMenuItems
      if (window.SuperApp.getters.isAgent()) return this.agentMenuItems
      if (window.SuperApp.getters.isProvider()) return this.providerNavMenuItems
      if (window.SuperApp.getters.isAdmin()) return this.adminNavMenuItems
      if (window.SuperApp.getters.isStore()) {
        if (window.SuperApp.getters.userInfo() && window.SuperApp.getters.userInfo().store_type) {
          const isFoodCategory = window.SuperApp.getters.userInfo().store_type.category === 'FOOD'
          const findBooking = this.shopNavMenuItems.find(x => x.route === 'shop-bookings')
          if (findBooking) {
            findBooking.hideInMenu = false
          }
          if (!isFoodCategory) {
            if (findBooking) {
              findBooking.hideInMenu = true
            }
          }
        }
        return this.shopNavMenuItems
      }
      return []
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
