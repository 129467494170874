<template>
  <div class="wrap-address-info user-addresses">
    <div
      v-if="showDefault"
      class="col-md-8 d-flex mx-auto"
      @click="showSelectAddress"
    >
      <div
        class="col-md-12 d-flex cursor-pointer p-1 py-50"
        style="background: #7367f0"
      >
        <img
          :src="require('@/assets/images/app-images/my_location_address.svg')"
          class="ml-2"
        >
        <div class="wrap-address text-white d-block w-100 text-center m-auto">
          <p class="mb-25">
            {{ $t('My Location') }}
          </p>
          <p class="mb-25">
            {{ selectedAddressInfo.map_address ? selectedAddressInfo.map_address : 'Select Address' }}
          </p>
        </div>
      </div>
    </div>
    <b-modal
      :id="showClose ? 'select-user-address-with-close' : 'select-user-address'"
      v-model="showSelectUserAddress"
      ok-only
      :ok-title="$t('Submit')"
      :no-close-on-backdrop="true"
      :class="{ 'show-close-button': showClose }"
      centered
      :title="$t('Select User Address')"
      @ok="submitSelectedAddress"
    >
      <b-col
        v-if="$route.meta.showPin"
        md="12"
        class="mb-2"
      >
        <b-input-group>
          <GmapAutocomplete
            :value="userAddressModal"
            class="form-control"
            @input="userAddressModal = $event.target.value"
            @place_changed="setPlacePinPoint"
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click="selectCurrentLocation"
            >
              <feather-icon icon="MapPinIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        v-if="isLoggedIn"
        md="12"
        class="cursor-pointer customer-card user-address-cards"
        @click="addNewAddress"
      >
        <b-card
          no-body
          style="height: 90%"
          class="card-address-info"
        >
          <b-card-body style="display: grid; place-content: center">
            <p class="font-20 text-black">
              {{ $t('Add new address') }} <feather-icon icon="PlusIcon" />
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <user-address-cards
        v-for="address of userAddressList"
        :key="address.id"
        :selected-address="selectedAddress"
        :address-obj="address"
        class="col-12"
        @deliver-here="deliverHere"
        @edit-address="openAddressModal"
        @delete-address="deleteAddress"
      />
    </b-modal>
    <b-modal
      v-model="toggleEditModal"
      :no-close-on-backdrop="true"
      :title="$t('Address Details')"
      :ok-title="$t('Save')"
      ok-only
      hide-footer
      size="lg"
      @ok="submitAddress"
    >
      <address-modal
        ref="addressModal"
        @address-selected="setPlace"
        @set-address="setAdress"
        @save-details="() => {
          toggleEditModal = false
          submitAddress()
        }"
      />
    </b-modal>
  </div>
</template>

<script>
import UserAddressCards from '@/views/users/shared/UserAddressCards.vue'
import { BCol, BCard, BCardBody, BInputGroup, BInputGroupAppend, BButton, BModal } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import AddressModal from '@/views/users/profile/AddressModal.vue'
import { useUserUi } from '@/views/users/useUser'
import eventBus from '@/libs/event-bus'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    UserAddressCards,
    BModal,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BCol,
    BCard,
    BCardBody,
    AddressModal,
  },
  props: {
    showDefault: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAddress: 0,
      selectedAddressInfo: {},
      isAccepted: false,
      newAddressSelected: {},
      showSelectUserAddress: false,
      toggleEditModal: false,
      addressInfo: [],
      editAddress: {},
      userAddressModal: '',
      markers: null,
      center: { lat: 45.508, lng: -73.587 },
    }
  },
  computed: {
    userAddressList() {
      return this.$store.state['app-user'].userAddressList
    },
    isLoggedIn() {
      return isUserLoggedIn()
    },
  },
  mounted() {
    const selectedUserAddress = window.SuperApp.actions.selectedUserAddress()
    const isAddressExist = this.userAddressList.find(x => x.id === selectedUserAddress)
    if (isAddressExist) {
      this.deliverHere(isAddressExist)
    }
    this.getUserAddress()
  },
  methods: {
    openAddressModal(address) {
      this.editAddress = address
      this.toggleEditModal = true
      setTimeout(() => {
        this.$refs.addressModal.$data.editAddress = address
      }, 250)
    },
    deleteAddress(address) {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this address?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteAddressDetail(address.id)
        }
      })
    },
    deleteAddressDetail(id) {
      showLoader()
      const { userDeleteAddress } = useUserUi()
      userDeleteAddress(id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.getUserAddress()
            showSuccessNotification(this, data.message)
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setPlacePinPoint(payload) {
      this.showSelectUserAddress = false
      this.setAddressName(payload.geometry.location.lat(), payload.geometry.location.lng())
      eventBus.$emit('location-searched', payload)
    },
    setAddressName(latitude, longitude) {
      window.SuperApp.helper.getFormattedAddress(`${parseFloat(latitude)},${parseFloat(longitude)}`).then(res => {
        this.userAddressModal = window.SuperApp.helper.parsedAddress(res)
        eventBus.$emit('userLocationChangedByMap', this.userAddressModal)
        this.showSelectUserAddress = false
      })
    },
    selectCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            eventBus.$emit('location-searched', {
              isCustom: true,
              lat: parseFloat(position.coords.latitude),
              lng: parseFloat(position.coords.longitude),
            })
            this.setAddressName(position.coords.latitude, position.coords.longitude)
          },
          () => {
            showDangerNotification(this, 'Unable to get your location')
          },
        )
      } else {
        showDangerNotification(this, 'Sorry, your browser does not support geolocation services')
      }
    },
    setPlace(place) {
      if (place) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.editAddress.map_address = place.formatted_address
        this.editAddress.latitude = marker.lat
        this.editAddress.longitude = marker.lng
        this.markers = {
          position: marker,
        }
        this.center = marker
      }
    },
    setAdress(place) {
      this.editAddress = JSON.parse(JSON.stringify(place))
    },
    addNewAddress() {
      this.editAddress = {
        map_address: '',
        flat_no: '',
        street: '',
        address_type: '',
        id: 0,
        building: '',
        zipcode: '',
      }
      this.toggleEditModal = true
    },
    submitAddress() {
      this.setNewAddress(this.editAddress)
    },
    getUserAddress() {
      if (!isUserLoggedIn()) {
        return
      }
      if (window.addressCallInProgress) {
        return
      }
      if (!window.addressCallInProgress) {
        window.addressCallInProgress = true
      }
      const { userAddressInfo } = useUserUi()

      userAddressInfo()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.addressInfo = data.responseData.reverse()
            this.$store.commit('app-user/UPDATE_USER_ADDRESS_LIST', data.responseData)
            const selectedUserAddress = window.SuperApp.actions.selectedUserAddress()
            const isAddressExist = this.addressInfo.find(x => x.id === selectedUserAddress)
            // if (!isAddressExist) {
            //   this.deliverHere(this.addressInfo[0])
            //   this.submitSelectedAddress()
            // }
          }
          window.addressCallInProgress = false
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setNewAddress(address) {
      const { userAddNewAddress, updateUserAddress } = useUserUi()
      const formData = new FormData()
      formData.append('id', address.id)
      formData.append('map_address', address.map_address)
      formData.append('latitude', address.latitude)
      formData.append('longitude', address.longitude)
      formData.append('flat_no', address.flat_no)
      formData.append('street', address.street)
      formData.append('address_type', address.address_type)
      formData.append('building', address.building)
      formData.append('zipcode', address.zipcode)
      const callType = address.id ? updateUserAddress : userAddNewAddress
      if (address.id) {
        formData.append('_method', 'PATCH')
      }
      showLoader()
      callType(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.getUserAddress()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    showSelectAddress() {
      // if (!window.location.href.includes('/service/checkout')) {
      //   this.showSelectUserAddress = true
      // }
      this.showSelectUserAddress = true
    },
    deliverHere(selectedAddress) {
      if(typeof selectedAddress === typeof undefined) return;

      if (selectedAddress.id !== this.selectedAddress) {
        this.newAddressSelected = selectedAddress
        this.isAccepted = false
        this.selectedAddress = selectedAddress.id
        localStorage.setItem('userSelectedAddress', this.selectedAddress)
        localStorage.setItem('providerUserSelectedAddress', this.selectedAddress)
        localStorage.setItem('userSelectedAddressObj', JSON.stringify(selectedAddress))
        this.selectedAddressInfo = selectedAddress
      }
    },
    submitSelectedAddress() {
      if (!window.SuperApp.actions.selectedUserAddress()) {
        showDangerNotification(this, 'Please select location')
        this.$nextTick(() => {
          this.showSelectUserAddress = true
        })
        return
      }
      this.showSelectUserAddress = false
      this.isAccepted = true
      this.$emit('get-store-list')
      if (this.showClose) {
        eventBus.$emit('new-address-selected', this.newAddressSelected)
        this.$emit('set-new-address', this.newAddressSelected.map_address)
      }
    },
  },
}
</script>

<style>
#select-user-address button.close {
  display: none;
}
</style>
