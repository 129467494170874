<template>
  <div class="user-notifications-component">
    <b-modal
      id="userNotificationModal"
      v-model="showNotificationsModal"
      :no-close-on-backdrop="true"
      :title="$t('Notifications')"
      @hidden="hideNotifications"
    >
      <b-col
        v-for="(items, index) of userData"
        :key="index"
        cols="12"
        md="12"
      >
        <div
          class="card mb-1 border-bottom"
          style="border-color: #000 !important;"
        >
          <div
            class="card-body py-1"
          >
            <div class="row d-flex flex-wrap align-items-center">
              <div class="col-md-12">
                <p class="mb-0">
                  {{ $t('Title') }}: {{ items.title }}
                </p>
                <p class="mb-0">
                  {{ items.descriptions }}
                </p>
                <div class="d-flex justify-content-between">
                  <p class="mb-0">
                    {{ $t('Validity Till') }}: {{ dateFormat(items.expiry_date) }}
                  </p>
                  <p
                    class="text-underline mb-0"
                    @click="goToRequest(items)"
                  >
                    {{ $t('View') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCol } from 'bootstrap-vue'
import { useUserUi } from '@/views/users/useUser'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'

export default {
  components: {
    BModal,
    BCol,
  },
  data() {
    return {
      showNotificationsModal: false,
      userData: [],
    }
  },
  mounted() {
    eventBus.$on('show-users-notifications', () => {
      this.getUserNotifications()
    })
  },
  destroyed() {
    eventBus.$off('show-users-notifications')
  },
  methods: {
    goToRequest(item) {
      if (item.service === 'SERVICE' && item.request_id) {
        this.$router.push({
          path: `/user/service/request/${item.request_id}`,
        })
      }
    },
    dateFormat(date) {
      return new Date(date).toLocaleDateString()
    },
    hideNotifications() {
      this.showNotificationsModal = false
    },
    getUserNotifications() {
      if (this.userData && this.userData.length === 0) {
        const { userNotifications } = useUserUi()
        showLoader()
        userNotifications()
          .then(({ data }) => {
            if (data.statusCode === '200') {
              if (data.responseData && data.responseData.notification && data.responseData.notification.data) {
                this.userData = data.responseData.notification.data
                this.showNotificationsModal = true
              }
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      } else {
        this.showNotificationsModal = true
      }
    },
  },
}
</script>

<style>
#userNotificationModal .modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
