/* eslint-disable global-require */
/* eslint-disable eol-last */
const appImageUrlPrefix = `${process.env.VUE_APP_API_BASE_END_POINT}/images`

export default [
  {
    title: 'CMS Pages',
    image: `${appImageUrlPrefix}/icons/cms_page.svg`,
    children: [
      {
        title: 'FAQ',
        route: 'admin-cmspages-faq',
        image: `${appImageUrlPrefix}/icons/faq.svg`,
      },
      {
        title: 'FAQ Category',
        route: 'admin-cmspages-faq-category',
        image: `${appImageUrlPrefix}/icons/faq_category.svg`,
      },
      {
        title: 'Blogs',
        route: 'admin-cmspages-blog',
        image: `${appImageUrlPrefix}/icons/blog.svg`,
      },
      {
        title: 'Knowledge Base',
        route: 'admin-cmspages-knowledge-base',
        image: `${appImageUrlPrefix}/icons/knowledge_base.svg`,
      },
      {
        title: 'Knowledge Base Category',
        route: 'admin-cmspages-knowledge-base-category',
        image: `${appImageUrlPrefix}/icons/knowledge_base_category.svg`,
      },
      {
        title: 'Knowledge Base Questions',
        route: 'admin-cmspages-knowledge-base-questions',
        image: `${appImageUrlPrefix}/icons/knowledge_base_questions.svg`,
      },
      {
        title: 'Others',
        route: 'admin-cmspages-other-pages',
        image: `${appImageUrlPrefix}/icons/other.svg`,
      },
    ],
  },
  {
    title: 'Utilities',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Variations',
        route: 'admin-utilities-variations',
        image: `${appImageUrlPrefix}/icons/variation.svg`,
      },
    ],
  },
  {
    title: 'Settings',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      {
        title: 'Languages',
        route: 'admin-language-translations',
        image: `${appImageUrlPrefix}/icons/language.svg`,
      },
      {
        title: 'System Settings',
        route: 'admin-system-settings',
        image: `${appImageUrlPrefix}/icons/system_setting.svg`,
      },
    ],
  },
  {
    title: 'Registered',
    image: `${appImageUrlPrefix}/icons/registered.svg`,
    children: [
      {
        title: 'Shops',
        route: 'admin-shops',
        image: `${appImageUrlPrefix}/icons/shop.svg`,
      },
      {
        title: 'Providers',
        route: 'admin-providers',
        image: `${appImageUrlPrefix}/icons/provider.svg`,
      },
      {
        title: 'User',
        route: 'admin-users',
        image: `${appImageUrlPrefix}/icons/user.svg`,
      },
      {
        title: 'Manage Companies',
        route: 'admin-manage-companies',
        image: `${appImageUrlPrefix}/icons/user.svg`,
      },
    ],
  },
  {
    title: 'Subscription Package',
    image: `${appImageUrlPrefix}/icons/subscription_package.svg`,
    children: [
      {
        title: 'Store Subscription Package',
        route: {
          path: '/admin/subscription-packages/store'
        },
        image: `${appImageUrlPrefix}/icons/shop.svg`,
      },
      {
        title: 'Provider Subscription Package',
        route: {
          path: '/admin/subscription-packages/provider'
        },
        image: `${appImageUrlPrefix}/icons/provider.svg`,
      },
      {
        title: 'User Subscription Package',
        route: {
          path: '/admin/subscription-packages/user'
        },
        image: `${appImageUrlPrefix}/icons/user.svg`,
      },
    ],
  },
  {
    title: 'Subscribers',
    image: `${appImageUrlPrefix}/icons/subscribers.svg`,
    children: [
      {
        title: 'Store Subscribers',
        route: {
          path: '/admin/subscribers/store'
        },
        image: `${appImageUrlPrefix}/icons/shop.svg`,
      },
      {
        title: 'Provider Subscribers',
        route: {
          path: '/admin/subscribers/provider'
        },
        image: `${appImageUrlPrefix}/icons/provider.svg`,
      },
      {
        title: 'User Subscribers',
        route: {
          path: '/admin/subscribers/user'
        },
        image: `${appImageUrlPrefix}/icons/user.svg`,
      },
    ],
  },
  {
    title: 'Coupons',
    route: 'admin-coupons',
    image: `${appImageUrlPrefix}/icons/coupon.svg`,
  },
  {
    title: 'AI Templates',
    route: 'admin-ai-templates',
    image: `${appImageUrlPrefix}/icons/ai_templates.svg`,
  },
  {
    title: 'Affiliate Withdraw',
    route: 'admin-affilation-requests',
    image: `${appImageUrlPrefix}/icons/affiliate_withdraw.svg`,
  },
  {
    title: 'Import Stores/Providers',
    image: `${appImageUrlPrefix}/icons/import_stores.svg`,
    children: [
      {
        title: 'Import Stores/Providers',
        route: 'admin-import-users',
        image: `${appImageUrlPrefix}/icons/shop.svg`,
      },
    ],
  },
]
