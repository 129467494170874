<template>
  <div class="agent-wrapper-dashboard agent-incoming-request">
    <b-modal
      id="incomingAgentCardsModalShared"
      v-model="showIncomingOrders"
      :no-close-on-backdrop="true"
      :title="$t('Incoming Orders') + ' (' + incomingOrderRequest.length + ')'"
      hide-footer
      @hidden="showIncomingOrders = false"
    >
      <div class="agent-cards-modal wrap-agent-cards main-card-block">
        <agent-request-card
          v-for="(item, index) of incomingOrderRequest"
          :key="index"
          :item="item"
          :that="that"
          :show-timer="true"
          @hide-orders-modal="openFullScreen = false"
        />
        <p
          v-if="incomingOrderRequest.length === 0"
          class="text-center"
        >
          {{ $t('There are no incoming orders') }}
        </p>
      </div>
    </b-modal>
    <b-modal
      id="agentOrderModalShared"
      v-model="viewOrderDetailModal"
      :no-close-on-backdrop="true"
      :title="$t('Order Details')"
      hide-footer
      @hidden="viewOrderDetailModal = false"
    >
      <order-info
        :order-detail="orderDetail"
        @close-order-info="closeOrderInfo"
      />
    </b-modal>
    <b-modal
      v-model="showCancelOrderModal"
      :title="$t('Please enter cancel reason')"
      :no-close-on-backdrop="true"
      @ok="cancelTheOrder"
      @hidden="showCancelOrderModal = false"
    >
      <b-col md="12">
        <b-form-group :label="$t('Cancel Reason')">
          <b-form-radio-group
            v-model="cancelReason"
          >
            <b-form-radio
              v-for="(item, index) of cancelReasonsList"
              :key="index"
              :value="item.id"
            >
              {{ item.reason }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import { BCol, BFormGroup, BModal, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import AgentRequestCard from '@/views/provider/shared/AgentRequestCard.vue'
import eventBus from '@/libs/event-bus'
import OrderInfo from './OrderInfo.vue'
import { useProviderUi } from '../useProvider'

export default {
  components: {
    AgentRequestCard,
    OrderInfo,
    BModal,
    BFormRadioGroup,
    BFormRadio,
    BCol,
    BFormGroup,
  },
  data() {
    return {
      incomingOrderRequest: [],
      showCancelOrderModal: false,
      orderDetail: {},
      cancelReason: '',
      cancelRequestPayload: null,
      viewOrderDetailModal: false,
      showIncomingOrders: false,
      cancelReasonsList: [],
    }
  },
  computed: {
    agentInfo() {
      return window.SuperApp.getters.userInfo()
    },
    isProviderAgent() {
      return this.agentInfo.parent_type === 'PROVIDER'
    },
  },
  mounted() {
    eventBus.$on('show-incoming-request', this.openIncomingRequest)
    eventBus.$on('show-cancel-agent-request', payload => {
      this.cancelRequestPayload = payload
      this.showCancelOrderModal = true
    })
    this.cancelReasonsAgent()
  },
  destroyed() {
    eventBus.$off('show-incoming-request', this.openIncomingRequest)
    eventBus.$off('show-cancel-agent-request')
  },
  methods: {
    closeOrderInfo() {
      this.viewOrderDetailModal = false
    },
    openIncomingRequest() {
      this.showIncomingOrders = true
      this.incomingOrders()
    },
    cancelTheOrder() {
      // eslint-disable-next-line eqeqeq
      const reason = this.cancelReasonsList.find(x => x.id == this.cancelReason)
      if (!reason) {
        showDangerNotification(this, 'Please choose cancel reason')
        return
      }
      eventBus.$emit('reload-agent-orders', reason ? reason.reason : 'Cancelled by agent')
    },
    openViewOrder(props) {
      this.orderDetail = props
      this.viewOrderDetailModal = true
    },
    cancelReasonsAgent() {
      const { cancelReasonsAgent } = useProviderUi()

      cancelReasonsAgent('ORDER')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cancelReasonsList = data.responseData
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    updateRequestApproval(props, nextStep = '') {
      this.orderAction = props
      this.orderAction.nextStatus = nextStep
      if (!nextStep) {
        this.$swal({
          title: this.$t('You want to accept this request?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acceptOrderRequest()
          }
        })
      }
    },
    getDeliveryAddress(payload) {
      if (!payload.delivery) return ''
      const address = []
      address.push(payload.delivery.flat_no)
      address.push(payload.delivery.street)
      address.push(payload.delivery.map_address)
      return address.filter(x => x).join(', ')
    },
    ignoreOrder(props) {
      this.orderAction = props
      this.$swal({
        title: this.$t('You want to ignore this request?'),
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.ignoreOrderRequest()
        }
      })
    },
    ignoreOrderRequest() {
      showLoader()
      const { ignoreOrder } = useProviderUi()
      ignoreOrder(this.orderAction.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message ? data.message : data.responseData.message)
            this.incomingOrders()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    acceptOrderRequest() {
      showLoader()
      const { acceptOrderRequest } = useProviderUi()
      const formData = new FormData()
      formData.append('id', this.orderAction.id)
      formData.append('admin_service', 'ORDER')
      acceptOrderRequest(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData.status === 422) {
              showDangerNotification(this, data.message ? data.message : data.responseData.message)
            } else {
              showSuccessNotification(this, data.message ? data.message : data.responseData.message)
              this.incomingOrders()
            }
          } else if (data.message) {
            showDangerNotification(this, data.message)
          } else if (data.responseData.message) {
            showDangerNotification(this, data.responseData.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    incomingOrders() {
      const { incomingOrders, incomingServices } = useProviderUi()
      const currentType = this.isProviderAgent ? incomingServices : incomingOrders
      showLoader()
      currentType('?page=all')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.incomingOrderRequest = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/agents.scss';
</style>
