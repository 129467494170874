<template>
  <div
    class="navbar-header d-xl-block d-none"
    style="width: 25%;"
  >
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          :to="redirectUrl"
        >
          <!-- <span class="brand-logo">
            <b-img :src="appLogoImage" alt="logo" />
          </span> -->
          <h2 class="brand-text mb-0">
            <b-img
              :src="require('@/assets/images/logo/mom-logo-icon-60.png')"
              alt="logo"
            />
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  computed: {
    isUser() {
      return window.SuperApp.getters.isUser()
    },
    isAgent() {
      return window.SuperApp.getters.isAgent()
    },
    isAdmin() {
      return window.SuperApp.getters.isAdmin()
    },
    isStore() {
      return window.SuperApp.getters.isStore()
    },
    isProvider() {
      return window.SuperApp.getters.isProvider()
    },
    redirectUrl: {
      get(){
        if(this.isAdmin){
          return '/admin/cmspages/faq'
        }else if(this.isAgent){
          return '/provider/agent/dashboard'
        }else if(this.isProvider){
          return '/provider/requests'
        }else if(this.isStore){
          return '/shop/dashboard'
        }
        return '/user/dashboard'
      }
    }
  }
}
</script>

<style>
body .horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  left: 25px;
}
</style>
