<template>
  <div
    class="customer-card user-address-cards"
    :class="defaultCol"
  >
    <render-address
      :address="addressObj"
      :show-use-address="true"
      :selected-address="selectedAddress"
      @deliver-here="deliverHere"
      @edit-address="editAddress"
      @delete-address="deleteAddress"
    />
  </div>
</template>

<script>
import RenderAddress from '../profile/RenderAddress.vue'

export default {
  components: {
    RenderAddress,
  },
  props: {
    addressObj: {
      type: Object,
      default: () => {},
    },
    selectedAddress: {
      type: Number,
      default: 0,
    },
    defaultCol: {
      type: String,
      default: '',
    },
  },
  methods: {
    deliverHere(addressObj) {
      this.$emit('deliver-here', addressObj)
    },
    editAddress(addressObj) {
      this.$emit('edit-address', addressObj)
    },
    deleteAddress(addressObj) {
      this.$emit('delete-address', addressObj)
    },
  },
}
</script>

<style scoped>
.customer-card {
  margin-bottom: 20px;
}

.customer-card .card {
  height: 100%;
  margin: 0;
}
</style>
