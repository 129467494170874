<template>
  <div class="agent-nav-items">
    <incoming-request />
    <NavChatBar />
  </div>
</template>

<script>
import IncomingRequest from './IncomingRequest.vue'
import NavChatBar from '@/components/common/NavChatBar.vue';

export default {
  components: {
    IncomingRequest,
    NavChatBar
  },
}
</script>

<style scoped>
.agent-nav-items .feather-icon-wrapper {
  margin: 0px 10px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  /* .agent-nav-items .feather-icon-wrapper {
    margin: 0px 2px;
  } */

  .agent-nav-items .feather-icon-wrapper li {
    float: unset;
  }
}
</style>